
import React from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import {default as StyleGuideContent} from "../style-guide";

const StyleGuide = ({ header, footer}) => {
  const { menuv3 } = footer;

  return (
    <>
      <Header header={header} />
      <Breadcrumbs isBg="yes" title="Style Guide" />
      <StyleGuideContent isBg=""/>
      <Footer isBg="yes" menu={menuv3} />
    </>
  );
};

export default StyleGuide;
