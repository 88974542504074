import React from "react";
import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import ArticleList from "../articles";

const Articles = ({ header, footer }) => {
  const { menuv3 } = footer;

  return (
    <>
      <Header header={header} />
      <Breadcrumbs isBg="yes" title="Articles" />
      <ArticleList isBg="" />
      <Footer isBg="yes" menu={menuv3} />
    </>
  );
};

export default Articles;
