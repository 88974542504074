
import React from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import AllCourses from "../all-courses";
import Contact from "../contact";

const CoursesListing = ({ header, footer}) => {
  const { menuv3 } = footer;

  return (
    <>
      <Header header={header} />
      <Breadcrumbs isBg="yes" title="All Courses" />
      <AllCourses isBg=""/>
      <Contact />
      <Footer isBg="yes" menu={menuv3} />
    </>
  );
};

export default CoursesListing;
