/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import testimonialData from "../data/testimonial.json";

const Testimonialv2 = ({ isBg }) => {
  const { testimonialv2 } = testimonialData;

  return (
    // <!-- ========== Testimonial section start ========== -->
    <section
      id="reviews"
      className={`section-padding testimonial ${
        isBg === "yes" ? "bg-two" : ""
      }`}
      style={{ backgroundColor: "#113d63" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center mb-5 mt-5 ">
              <h2 className="display-5 mb-4 text-light" style={{ lineHeight: "1.5em", fontWeight:500}}>"{testimonialv2.title}"</h2>
              <span className="text-light">&mdash;{testimonialv2.subtitle}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Testimonial section end ========== -->
  );
};

export default Testimonialv2;
