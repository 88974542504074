/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ArticlesGallery from './articles-gallery';

const ArticlesList = () => {
    return (
        <section className="section-padding all-blog2">
        <div className="container">
          <div className="row">
            <div
              className="col-md-7 col-lg-8 col-xl-9 mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <ArticlesGallery />
            </div>
            <div
              id="right"
              className="col-md-5 col-lg-4 col-xl-3"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="all-blog2__sidebar">
                <div className="all-blog2__sidebar__categories">
                  <iframe src="https://policewriting.substack.com/embed" width="300" height="525" style={{ border: "none" }} frameborder="0" scrolling="no" title="embedsignup"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default ArticlesList;