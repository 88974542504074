/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../data/courses.json";
import { FaRegClock, FaDollarSign } from "react-icons/fa";
import { Link } from "react-router-dom";

const AllCourses = () => {
  const { courses } = data;
  return (
    <div className="section-padding events">
      <div className="container">
        <h1 className="mb-2">3 Minute Lessons</h1>
        <h5 className="mb-5">Brush up your skills with these free micro courses.</h5>
        <div className="row">
          {courses.threeMinuteLessons.map((data, i) => (
            <div
              key={i}
              className="col-md-6 col-lg-3 mb-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay={(i + 2) * 25}
            >
              <article className="events__single-event h-100 translateEffect1">
                <div className="events__single-event__image">
                  <Link to={data.link} target="new">
                    <img className="img-fluid" src={data.image} alt="" />
                  </Link>
                </div>
                <div className="events__single-event__body">
                  <div className="events__single-event__content">
                    <h2 className="fs-4">
                      <Link to="/single-event">{data.title}</Link>
                    </h2>
                    <p className="m-0">{data.description}</p>
                  </div>
                  <div className="events__single-event__meta">
                    <div>
                      <FaRegClock /> {data.time}
                    </div>
                    <div>
                      <FaDollarSign /> {data.price}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
        <h1 className="mb-2 mt-5">Primers</h1>
        <h5 className="mb-5">Learn the Building Blocks of Police Report Writing</h5>
        <div className="row">
          {courses.threeMinuteLessons.map((data, i) => (
            <div
              key={i}
              className="col-md-6 col-lg-3 mb-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay={(i + 2) * 25}
            >
              <article className="events__single-event h-100 translateEffect1">
                <div className="events__single-event__image">
                  <Link to={data.link} target="new">
                    <img className="img-fluid" src={data.image} alt="" />
                  </Link>
                </div>
                <div className="events__single-event__body">
                  <div className="events__single-event__content">
                    <h2 className="fs-4">
                      <Link to="/single-event">{data.title}</Link>
                    </h2>
                    <p className="m-0">{data.description}</p>
                  </div>
                  <div className="events__single-event__meta">
                    <div>
                      <FaRegClock /> {data.time}
                    </div>
                    <div>
                      <FaDollarSign /> {data.price}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
